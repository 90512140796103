import React from "react"; 


export default function Movie(props) {
    return (


    <figure className="movie">
        <img src={props.image} alt= "the life of other"/>
        <div className="overlay">
        <h6>{props.name}</h6>
        </div>
        
    </figure> 
    
    

    )
   
}