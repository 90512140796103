import React from 'react';

import Gallery from "./Gallery.js"
import './App.css';

function App() {
  return (
    <div className="App">

      <Gallery/>
  

    </div>
  );
}



export default App;



/* <figure className="movie">
        <img src={DarkKnight} alt="Dark Knight Movie" />
        </figure>

        <figure className="movie">
        <img src={twentyFourHurs} alt="24 hours season 2" />
        </figure> */

/* <figure className="movie">
      <img src={Homeland} alt="Homeland last Season" />
        </figure>
        <figure className="movie">
      <img src={twelveYearsASlave} alt="12 years a slave" />
        </figure>
        <figure className="movie">
      <img src={bladeRunner} alt="blade Runner 2049" />
        </figure> */
