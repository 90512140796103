import React from 'react';

import drive from "./Images1/Drive 1000*1500.jpg";
import jasonBourne from "./Images1/Jason Bourne 1000*1500.jpg";
import spiderMan from "./Images1/spiderman 1000*1500 .jpg";
import theDarkKnight from "./Images1/The Dark Knight.jpg";
import lordOfTheRings from "./Images1/The Lord of the Rings  1000*1500.jpg";
import theDeparted from "./Images1/the-departed 1000*1500.jpg";
import Movie from "./Movie"

export default function Gallery() {
    return ( 
      <div>
    <section className="rowContainer">
            <Movie name="Drive" image={drive} />
            <Movie name="Jason Bourne" image={jasonBourne} />
            <Movie name="Spider Man" image={spiderMan} />
    </section>  
    <section className="rowContainer">
            <Movie name="The Dark" image={theDarkKnight} />
            <Movie name="The Lord Image" image={lordOfTheRings} />
            <Movie name="The Departred" image={theDeparted} />
    </section>
    </div>
  
    )
  }